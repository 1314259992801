<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <!-- <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' /> -->
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :columns='columns' :data='loadData'>
        <span slot='id' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              <ellipsis :length='10' tooltip>{{ text }}</ellipsis>
            </a>
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text=="online"' dot status='processing' :text='"启用"' />
            <a-badge v-if='text=="offline"' dot status='warning' :text='"停用"' />
          </template>
        </span>
        <!-- <span slot='level' slot-scope='text'>
          <template>
            <span v-if="text==1">A</span>
            <span v-if="text==2">B</span>
            <span v-if="text==3">C</span>
            <span v-if="text==4">服务中心</span>
            <span v-if="text==5">总裁</span>
          </template>
        </span> -->
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <!-- <a @click='handleRewardEdit(record)'>奖励设置</a> -->
              <a v-if='record.status=="online"' @click='handleEdit(record)'>编辑</a>
              <a v-if='record.status=="online"' @click='handleStatus(record,"offline")'>停用</a>
              <a v-if='record.status=="offline"' @click='handleStatus(record,"online")'>启用</a>
              <!-- <a v-if='record.status=="offline"' @click='handleDelete(record)'>删除</a> -->
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
    <a-modal title='编辑' :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
             @cancel='()=>this.deliver.visible=false'
             ok-text='提交' cancel-text='取消' @ok='handleDeliver'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <!-- <a-form-model-item label='类型名称:' prop='name'>
          <a-input v-model='deliver.form.name' />
        </a-form-model-item> -->
        <a-form-model-item label='套餐价格:' prop='price'>
          <a-input v-model='deliver.form.price' />
        </a-form-model-item>
        <!-- <a-form-model-item label='消费频次:' prop='level'>
          <a-select v-model="deliver.form.level" placeholder="请选择">
            <a-select-option value="1">
              A
            </a-select-option>
            <a-select-option value="2">
              B
            </a-select-option>
            <a-select-option value="3" >
              C
            </a-select-option>
            <a-select-option value="4">
              服务中心
            </a-select-option>
            <a-select-option value="5" >
              总裁
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <!-- <a-form-model-item label='分类状态:' prop='status'>
           <a-radio-group name="radioGroup" :value="deliver.form.status">
            <a-radio value="online">
              启用
            </a-radio>
            <a-radio value="offline">
              停用
            </a-radio>
          </a-radio-group>
        </a-form-model-item> -->
      </a-form-model>
    </a-modal>
    <a-modal title='套餐奖励设置' :destroyOnClose='true' :maskClosable='true' :visible='deliver1.visible'
             @cancel='()=>this.deliver1.visible=false'
             ok-text='提交' cancel-text='取消' @ok='handleDeliver1' :width='1300'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' layout='inline'>
        <a-form-model-item label=' 当购买者无消费频率时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='defaultStoreDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.defaultStoreDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励:' prop='defaultStoreReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.defaultStoreReward' />
        </a-form-model-item><br>
        <a-form-model-item label='送云店提成积分:' prop='defaultGiveStoreDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.defaultGiveStoreDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送云店奖励积分:' prop='defaultGiveStoreDeductReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.defaultGiveStoreDeductReward' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='defaultGiveCenterReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.defaultGiveCenterReward' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='defaultGivePresidentReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.defaultGivePresidentReward' />
        </a-form-model-item><br>
        <a-form-model-item label='当购买者消费频率为C时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='cStoreDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.cStoreDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励:' prop='cStoreReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.cStoreReward' />
        </a-form-model-item><br>
        <a-form-model-item label='送云店提成积分:' prop='cGiveStoreDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.cGiveStoreDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送云店奖励积分:' prop='cGiveStoreDeductReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.cGiveStoreDeductReward' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='cGiveCenterReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.cGiveCenterReward' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='cGivePresidentReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.cGivePresidentReward' />
        </a-form-model-item><br>
        <a-form-model-item label='当购买者消费频率为B时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='bStoreDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.bStoreDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励:' prop='bStoreReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.bStoreReward' />
        </a-form-model-item><br>
        <a-form-model-item label='送云店提成积分:' prop='bGiveStoreDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.bGiveStoreDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送云店奖励积分:' prop='bGiveStoreDeductReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.bGiveStoreDeductReward' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='bGiveCenterReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.bGiveCenterReward' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='bGivePresidentReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.bGivePresidentReward' />
        </a-form-model-item><br>
        <a-form-model-item label='当购买者消费频率为A时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='aStoreDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.aStoreDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励:' prop='aStoreReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.aStoreReward' />
        </a-form-model-item><br>
        <a-form-model-item label='送云店提成积分:' prop='aGiveStoreDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.aGiveStoreDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送云店奖励积分:' prop='aGiveStoreDeductReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.aGiveStoreDeductReward' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='aGiveCenterReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.aGiveCenterReward' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='aGivePresidentReward' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='deliver1.form.aGivePresidentReward' />
        </a-form-model-item><br>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryMealCategories, deleteMealCategory, putMealCategory,putMealCategoryPost } from '@/api/meal'

const columns = [
  {
    title: '分类id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '分类名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '套餐价格',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '分类状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  // {
  //   title: '消费频次',
  //   dataIndex: 'level',
  //   scopedSlots: { customRender: 'level' }
  // },
  {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ProductionMealType',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryMealCategories(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      },
      deliver: { 
        visible: false, 
        form: {
          name: "",
          price: "",
          level: undefined,
          status: "offline"
        },
        rules: {
          name: [{ required: true, message: '请填写类型名称', trigger: 'change' }],
          level: [{ required: true, message: '请填写消费频率', trigger: 'change' }]
        } 
      }, //编辑弹窗
      deliver1: { 
        visible: false, 
        form: {
          aGiveCenterReward: "",
          aGivePresidentReward: "",
          aGiveStoreDeductReward: "",
          aGiveStoreDeductScore: "",
          aStoreDeduct: "",
          aStoreReward: "",
          bGiveCenterReward: "",
          bGivePresidentReward: "",
          bGiveStoreDeductReward: "",
          bGiveStoreDeductScore: "",
          bStoreDeduct: "",
          bStoreReward: "",
          cGiveCenterReward: "",
          cGivePresidentReward: "",
          cGiveStoreDeductReward: "",
          cGiveStoreDeductScore: "",
          cStoreDeduct: "",
          cStoreReward: "",
        },
        rules: {
          aGiveCenterReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          aGivePresidentReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          aGiveStoreDeductReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          aGiveStoreDeductScore: [{ required: true, message: '内容为必填', trigger: 'change' }],
          aStoreDeduct: [{ required: true, message: '内容为必填', trigger: 'change' }],
          aStoreReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          bGiveCenterReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          bGivePresidentReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          bGiveStoreDeductReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          bGiveStoreDeductScore: [{ required: true, message: '内容为必填', trigger: 'change' }],
          bStoreDeduct: [{ required: true, message: '内容为必填', trigger: 'change' }],
          bStoreReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          cGiveCenterReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          cGivePresidentReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          cGiveStoreDeductReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
          cGiveStoreDeductScore: [{ required: true, message: '内容为必填', trigger: 'change' }],
          cStoreDeduct: [{ required: true, message: '内容为必填', trigger: 'change' }],
          cStoreReward: [{ required: true, message: '内容为必填', trigger: 'change' }],
        } 
      }, //奖励设置编辑弹窗
    }
  },
  created() {

  },
  methods: {
    handleDeliver() {
      this.$refs.form.validate(result => {
       if (result) {
        putMealCategory(this.deliver.form).then(() => {
          this.$message.success('操作成功')
          this.deliver.visible = false;
          this.$refs.table.refresh(true)
        })
       }
      })
    },
    handleDeliver1() {
      this.$refs.form1.validate(result => {
       if (result) {
        putMealCategoryPost(this.deliver1.form).then(() => {
          this.$message.success('操作成功')
          this.deliver1.visible = false;
          this.$refs.table.refresh(true)
        })
       }
      })
    },
    handleNew() {
      this.deliver.visible = true;
      this.deliver.form = {
        name: "",
        price: "",
        level: undefined,
        status: "offline"
      };
    },
    handleRewardEdit(record) {
      // this.deliver1.visible = true;
      // this.deliver1.form = record;
      this.$router.push({ path: '/production/type/edit', query: { id: record.id } })
    },
    handleEdit(record) {
      this.deliver.visible = true;
      this.deliver.form = record;
    },
    handleStatus(record, status) {
      putMealCategory(Object.assign({}, record, { status: status })).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      deleteMealCategory({ record }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>
